import React, { useState } from "react";
import styled from "styled-components";

export default function Image({ src, alt = "", ...props }) {
  const [show, setShow] = useState(false);

  return (
    <Img
      src={src}
      alt={alt}
      className={show ? "show" : ""}
      onLoad={() => setShow(true)}
      {...props}
    />
  );
}

const Img = styled.img`
  opacity: 0;
  filter: blur(4px);

  &.show {
    opacity: 1;
    filter: none;
    transition: all 0.8s ease-out;
  }

  &:hover {
    transform: scale(1.05);
    transform-origin: center;
    transition: transform 0.4s ease-out;
  }
`;
