import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useContext,
  useMemo,
} from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { Link, navigate } from "gatsby";
import useDimension from "../../hooks/use-dimension";
import * as Theme from "../../Theme";
import Constants from "../../constants";
import ArticleItem from "./ArticleItem";
import Carousel from "./Article.carousel";
import ApiBlog from "../../ApiBlog";
import { Context } from "../../AppContext";
import RightSection from "./RightSection";
import { ArrowLeftOutlined, ArrowRightOutlined } from "../../Components/Icon";
const qs = require("query-string");

const DOCUMENT_NAME = "Article_Default";

function ListPage(props) {
  const { location, pageContext } = props;
  const { dimension, rwd } = useDimension();
  const [totalRecords, setTotalRecords] = useState(0);
  const [records, setRecords] = useState([]);
  const labels = ["全部文章", ...props.pageContext.labels];
  const script = document.createElement("script");
  const api = useRef(new ApiBlog()).current;
  const app = useContext(Context);

  const { page, keyword, label } = useMemo(() => {
    const { page = 1, keyword = "" } = qs.parse(location.search);
    return {
      page: parseInt(page),
      keyword,
      label: pageContext.label,
    };
  }, [location, pageContext.label]);

  const getRecords = useCallback(async () => {
    let limit = 8;
    try {
      const filters = [
        {
          label: { $not: { $regex: "FAQ" } },
        },
        {
          label: { $not: { $regex: "draft" } },
        },
      ];

      const search = [
        {
          $or: [{ title: { $regex: keyword } }, { html: { $regex: keyword } }],
        },
      ];

      if (label) {
        filters.push({
          label: { $regex: label },
        });
      }

      let resp = await api.getBlogs({
        documentName: DOCUMENT_NAME,
        paging: {
          offset: (page - 1) * limit,
          limit: limit,
        },
        $and: [...filters, ...search],
      });

      setTotalRecords(resp.total);
      setRecords(resp.results);

      app.actions.setDelighter(true);
    } catch (ex) {
      console.warn("fetch article error", ex);
    }
  }, [api, app.actions, page, keyword, label]);

  // initial
  useEffect(() => {
    (async () => {
      // get records
      app.actions.setLoading(true);
      await getRecords();
      app.actions.setLoading(false);
    })();
  }, [app.actions, getRecords]);

  // setting instagram widget
  // useEffect(() => {
  //   if (typeof document !== "undefined") {
  //     function importInstagramScript() {
  //       script.src = "https://platform.instagram.com/en_US/embeds.js";
  //       script.async = true;

  //       document.body.appendChild(script);
  //     }
  //     importInstagramScript();
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, []);

  return (
    <Wrapper rwd={rwd}>
      <div className="center-content">
        <Carousel rwd={rwd} items={records.slice(0, 5)} />

        <div className="content">
          <div className="left">
            {label && <h1>{label}</h1>}
            {keyword && (
              <h1>{`Search:“${keyword}”共${totalRecords}則相關文章`}</h1>
            )}
            {records.map((record, idx) => (
              <ArticleItem key={idx} article={record} style={{}} rwd={rwd} />
            ))}

            <div className="pagination-wrapper">
              <Ant.Pagination
                current={page}
                total={totalRecords}
                pageSize={8}
                showSizeChanger={false}
                itemRender={(current, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <Ant.Button
                        shape="circle"
                        icon={<ArrowLeftOutlined size={36} />}
                        style={{
                          background: "transparent",
                        }}
                      />
                    );
                  }

                  if (type === "next") {
                    return (
                      <Ant.Button
                        shape="circle"
                        icon={<ArrowRightOutlined size={30} />}
                        style={{
                          background: "transparent",
                        }}
                      />
                    );
                  }

                  if (type === "page") {
                    return (
                      <span
                        style={
                          current === page
                            ? {
                                color: "#000000",
                                fontSize: 14,
                              }
                            : {
                                opacity: 0.43,
                                color: "#707070",
                                fontSize: 14,
                              }
                        }
                      >
                        {current}
                      </span>
                    );
                  }

                  return originalElement;
                }}
                onChange={(page, pageSize) => {
                  navigate(
                    `${location.pathname}?page=${page}${
                      keyword ? "&keyword=" + keyword : ""
                    }`
                  );
                }}
              />
            </div>
            {keyword && (
              <div className="search-wrapper">{`Search:“${keyword}”共${totalRecords}則相關文章`}</div>
            )}
          </div>

          <div className="sep" />

          <div className="right">
            <RightSection
              labels={labels}
              onSearch={value =>
                navigate(
                  `${location.pathname}${value ? "?keyword=" + value : ""}`
                )
              }
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .center-content {
    max-width: 1240px;
    margin: 0px auto;
    /* padding: ${props => (props.rwd === "desktop" ? "0px" : "0px 20px")}; */

    & > .content {
      display: flex;
      flex-direction: ${props => (props.rwd === "desktop" ? "row" : "column")};
      padding: ${props => (props.rwd === "desktop" ? "0px" : "0px 20px")};
      padding-top: 60px;
      /* padding-top: ${props => {
        if (props.rwd === "desktop") {
          return "70px";
        } else if (props.rwd === "pad") {
          return "40px";
        } else {
          return "20px";
        }
      }}; */
      & > .left {
        /* padding: ${props =>
          props.rwd === "desktop" ? "0px 20px 0 0" : "0px"}; */
        flex: 1;
        min-height: 500px;
        & > h1 {
          font-size: ${props => (props.rwd === "mobile" ? "24px" : "30px")};
          padding-bottom: ${props =>
            props.rwd === "mobile" ? "15px" : "10px"};
          border-bottom: 1px solid ${Theme.colors.unSelected};
          margin-bottom: 40px;
        }

        & > .pagination-wrapper {
          margin: 100px 0px 50px;
          display: flex;
          justify-content: center;

          .ant-pagination-item{
            border: 0px;
            border-radius: 50%;
            background-color: #ffffff;
          }

          .ant-pagination-item-active {
            background-color: #cadbdb;
          }
        }

        & > .search-wrapper {
          margin-bottom: 50px;
          line-height: 1.5;
          color: #5a5a5a;
        }
      }

      & > .sep {
        flex-basis: 50px;
        flex-shrink: 0;
      }

      & > .right {
        flex-basis: 300px;
        flex-shrink: 0;
      }
    }
  }
`;

export default ListPage;
