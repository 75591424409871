import moment from "moment";

function format(date, type = "YYYY.MM.DD.ddd") {
  try {
    if (isNaN(date)) {
      throw new Error();
    }
    let value = date;

    // timestamp digit < 13, not millisecond
    if (date.toString().length < 13) {
      value = date * 1000;
    }

    // timezone offset;
    // const offset = new Date().getTimezoneOffset() * 60000;
    // const str = new Date(value - offset).toISOString();

    // formating YYYY-MM-DD HH:mm
    return moment(value)
      .format(type)
      .toUpperCase();
  } catch (error) {
    return "-";
  }
}

export default { format };
