import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import time from "../../Utils/TimeFormat";
import * as Theme from "../../Theme";
import Image from "../../Components/ImageWithLoading";

const RATIO = 440 / 760;
const circleSize = 50;

function ArticleItem(props) {
  const { article, rwd, style = {}, mini } = props;
  if (mini) {
    return (
      <Link to={`/article?id=${article.id}`}>
        <MiniWrapper rwd={rwd}>
          <MiniImage src={article.image} />
          <h3>{article.title}</h3>
        </MiniWrapper>
      </Link>
    );
  }

  return (
    <Link to={`/article/${article.id}`}>
      <Wrapper style={style} rwd={rwd}>
        <div className="container" data-delighter>
          <ImageWrapper>
            <Image src={article.image} width="100%" height="auto" />
          </ImageWrapper>
          <div className="subtitle">
            {article.label?.slice(0, 1)}
            <span style={{ margin: "0 10px" }}>|</span>
            {time.format(article.created)}
          </div>
          <h3>{article.title}</h3>
          {rwd !== "mobile" && <p>{article.outline || "No abstract"}</p>}
          <div className="readmore-row">
            <div className="img-wrapper">
              <div className="readmore" />
            </div>
          </div>
        </div>
        {rwd !== "mobile" && (
          <div className="circle">{time.format(article.updated, "MM.DD")}</div>
        )}
      </Wrapper>
    </Link>
  );
}

const MiniWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  & > h3 {
    flex: 1;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
`;

const MiniImage = styled.div`
  background-image: url("${props => props.src}");
  background-position: center cneter;
  background-repeat: no-repeat;
  background-size: cover;
  width: 130px;
  height: 90px;
  margin: 0 20px 30px 0;
`;

const Wrapper = styled.div`
  position: relative;
  border-right: ${props =>
    props.rwd !== "mobile" ? "1px solid " + Theme.colors.unSelected : "0px"};
  padding: ${props =>
    props.rwd !== "mobile" ? "0px 75px 60px 0px" : "0px 0px 33px 0px"};
  margin-right: ${props =>
    props.rwd !== "mobile" ? circleSize / 2 + "px" : "0"};
  
  & > .container {
    & > h3 {
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.05px;
      color: #3e3a39;
      font-size: ${props => {
        if (props.rwd === "desktop") {
          return "24px";
        } else if (props.rwd === "pad") {
          return "21px";
        } else {
          return "16px";
        }
      }};
    }

    & > figure {
      /* min-height: ${props => {
        if (props.rwd === "desktop") {
          return "440px";
        } else if (props.rwd === "pad") {
          return "340px";
        } else {
          return "200px";
        }
      }}; */

      position: relative;
      padding-bottom: ${RATIO * 100}%;
      overflow: hidden;
      
      & > img {
        position: absolute;
        top: 0;
      }
    }
    & > p {
    }
    & > .subtitle {
      white-space: break-spaces;
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 12px;
    }
    & > .readmore-row {
      display: flex;
      justify-content: flex-end;
      & > .img-wrapper {
        position: relative;
        width: ${props => (props.rwd === "desktop" ? "120px" : "100px")};
        height: 20px;
        cursor: pointer;
        & > .readmore {
          background-size: contain;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../../images/readmore.png");
        }
        & > .readmore:hover {
          background-image: url("../../images/readmore-hover.png");
        }
      }
    }
  }

  & > .container.delighter {
      transform: translateY(50px);
      transition: all 0.6s ease-out;
      opacity: 0;
    }

  & > .container.delighter.started {
    transform: none;
    opacity: 1;
  }

  
  & > .circle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    right: ${(circleSize / 2) * -1}px;
    top: 0px;
    width: ${circleSize}px;
    height: ${circleSize}px;
    border-radius: 50%;
    border: 1px solid ${Theme.colors.unSelected};
    color: ${Theme.colors.unSelected};
  }
`;

const ImageWrapper = styled.figure`
  position: relative;
  padding-bottom: ${RATIO * 100}%;
  overflow: hidden;
  margin-bottom: 0;

  & > img {
    position: absolute;
    top: 0;
    width: 100%;
    animation: zoomout 0.8s ease-out;
  }
`;

export default ArticleItem;
