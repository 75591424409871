import React from "react";
import Carousel from "../../Components/Carousel";
import styled from "styled-components";
import time from "../../Utils/TimeFormat";
import { Link } from "gatsby";
import Image from "../../Components/ImageWithLoading";

const RATIO = 722 / 1240;

export default function ArticleCarousel(props) {
  const { width = "100%", height = "100%", items, rwd, style = {} } = props;

  if (items.length <= 0) {
    return (
      <Wrapper style={style} rwd={rwd} isRow={rwd === "desktop"}>
        <div style={{ flex: 1, width: "100%" }}>
          <ImageWrapper>
            <div
              style={{
                backgroundColor: "#f0f0f0",
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </ImageWrapper>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper style={style} rwd={rwd} isRow={rwd === "desktop"}>
      <Carousel
        width={width}
        height={height}
        data={items}
        renderPrev={null}
        renderNext={null}
        renderDots={({ currIdx, goToIndex }) => {
          return (
            <FlexRow rwd={rwd}>
              <div className="btns-wrapper">
                {/* <Arrow
                onClick={() => goToIndex(currIdx - 1)}
                style={{
                  width: size,
                  height: size,
                  borderLeft: "none",
                  userSelect: "none",
                }}
              >
                <div className="arrow-prev" />
              </Arrow> */}

                <Arrow
                  onClick={() =>
                    goToIndex(currIdx === items.length - 1 ? 0 : currIdx + 1)
                  }
                  style={{
                    width: props.rwd === "desktop" ? 60 : 32,
                    height: props.rwd === "desktop" ? 60 : 32,
                    userSelect: "none",
                    marginLeft: 20,
                  }}
                >
                  <div className="arrow-next" />
                </Arrow>
              </div>
            </FlexRow>
          );
        }}
        renderItem={({ item }) => (
          <Link to={`/article?id=${item.id}`} style={{ flex: 1 }}>
            <ImageWrapper>
              <Image src={item.image} alt="blog image" />
            </ImageWrapper>
          </Link>
        )}
        disableSwipe
        renderTitle={({ data, currIdx }) => {
          const item = data[currIdx] || {};
          return (
            <div className="title-wrapper">
              <h1>{item.title}</h1>
            </div>
          );
        }}
        style={{ flex: 1, width: "100%" }}
        autoplay={true}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  & > .title-wrapper {
    position: absolute;
    left: 30px;
    bottom: 20px;
    max-width: 70%;
    & > h1 {
      background-color: rgba(100, 100, 100, 0.3);
      padding: ${props => (props.rwd === "desktop" ? "15px 20px" : "5px 20px")};
      font-weight: bold;
      line-height: 1.46;
      color: white;
      font-size: ${props => {
        if (props.rwd === "desktop") {
          return "28px";
        } else if (props.rwd === "pad") {
          return "21px";
        } else {
          return "16px";
        }
      }};
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  & .padding {
    flex: 1;
  }
  & .btns-wrapper {
    position: absolute;
    display: flex;
    bottom: 20px;
    right: 30px;
  }
  ${props => props.css}
`;

const Arrow = styled.div`
  z-index: 1;
  position: relative;
  width: 120px;
  height: 20px;
  cursor: pointer;
  & > .arrow-next {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/images/arrow.png");
  }
  & > .arrow-next:hover {
    background-image: url("/images/arrow-hover.png");
  }

  & > .arrow-prev {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/images/arrow-reverse.png");
  }
  & > .arrow-prev:hover {
    background-image: url("/images/arrow-reverse-hover.png");
  }
`;

const ImageWrapper = styled.figure`
  position: relative;
  padding-bottom: ${RATIO * 100}%;
  overflow: hidden;
  margin-bottom: 0;

  & > img {
    position: absolute;
    top: 0;
    width: 100%;
    animation: zoomout 0.8s ease-out;
  }
`;
